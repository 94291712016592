import { post, get, put } from "../core";

export const requestOTP = async (mobile: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-request-otp`, {mobile});
    return res.data;
  } catch (err) {
    return false;
  }
};

export const validateOTP = async (mobile: string, otp: string) => {
  try {
    let res = await post(`/customers/web/alleasy/register-validate-otp`, {mobile, otp});
    return res.data;
  } catch (err: any) {
    return err;
  }
};



export const registerUser = async (body: any) => {
  try {
    body.phoneNumber = `+63${body.phone}`

    let res = await post(`/customers/web/register`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const authenticateViaShareAuth = async (shareAuth: string, apiPathname: string) => { 

  try {
    
    console.log("dito", shareAuth)
    let res = await post(apiPathname, {
      shareAuth
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const authenticateViaPassword = async ({ userEmailPhone, password }: any) => {  
  if(!isNaN(userEmailPhone)){
    userEmailPhone = `+63${userEmailPhone}`
   }

  try {
    let res = await post(`/customers/web/login`, {
      userEmailPhone: userEmailPhone,
      password: password
    });

    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};


export const authenticateViaAllEasy = async ({ phone, pin }: any) => {
  try {
    let res = await post(`/customers/web/alleasy/login`, {
      mobile: phone,
      pin
    });

    return res.data;
  } catch (err) {
    throw err;
  }
};


export const getUserCredentials = async () => {
  try {
    let res = await get(`/customers/me`);

    return res.data;
  } catch (err) {
    throw err;
  }
};

export const updateUserProfile = async (body: any) => {
  try {
    const { data } = await put(`/profiles`, body);
    return {
      isSuccess: true
    };
  } catch (err) {
    throw err;
  }
};

export const updateUserBank = async (body: any) => {
  try {
    const { data } = await put(`/customer/save-billing-account`, body);
    return {
      isSuccess: true
    };
  } catch (err) {
    throw err;
  }
};

export const addAccountAddress = async (body: any, id?: any) => {
  try{
    const res = await put(`/customers/create/address/${id}`, body)
    return res.data;
  }catch(err) {
    throw err;
  }
}