import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { addParcel, scheduleParcelBooking, generatePdf, getOrderBreakdown } from "../common/api/endpoints/parcel";
import firebase from "firebase/app";
import 'firebase/firestore';
import { getAutocompleteSuggestions } from "../common/api/endpoints/location";

export interface State {
    isLoading: boolean,
    isLocationsLoading: boolean,
    data: Array<any>,
    selectedIds: Array<any>,
    locations: Array<any>,
    orderBreakdown: {} | null,
    formData: {},
}

export interface IData {
    listData: Array<any>
}

export interface IFilter {
    operator: any
}

export interface IFormData {
    name: string,
    value: string,
    identifier: string
}

export interface IScheduleBooking {
    parcelId: string,
    data: Date,
    address: string,
    area: string,
    name: string,
    email: string,
    phoneNumber: string
}

const initialState = {
    isLoading: false,
    isLocationsLoading: false,
    orderBreakdown: null,
    selectedIds: [],
    data: [],
    locations: [],
    formData: {
        packageDetails: {
            value: '', 
            size: '',
            // weight: '',
            // height: '',
            // length: '',
            width : '',
            transportMode: '',
            itemDescription: '', 
          },
          consignee: {
              consigneeName: '',
              address: '',
              phoneNumber: '',
              email: '', 
              locationDetails : '',
          },
          pickUpDate: null,
          reference1 : '',
          reference2 : '',
          reference3 : '',
          remarks: ''
    }
} as State;

export const Parcel = createModel<RootModel>()({
    state: initialState,
    reducers: {
        resetState() {
            return { ...initialState }
        },
        setFormData(state, payload: IFormData) {
            const { name, value, identifier }: any = payload;
            const mark = identifier === 'none' ? name : identifier ;
            return {
                ...state,
                formData: {
                    ...state.formData,
                    [mark]: identifier === 'none' ? value : { ...state.formData[mark], [name]: value }
                }
            }
        },
        setData(state, payload: IData){
            return {
                ...state,
                data: payload.listData
            }
        },
        resetFormData(state) {
            return {
                ...state,
                formData:{
                    ...initialState.formData
                }
            }
        },
        updateState(state, newState: Partial<State>) {
            return {
                ...state, ...newState
            }
        }
    },
    effects: (dispatch) => ({
        async addParcel(payload, rootState) {
            try{
                dispatch.UI.setIsLoading(true);
                const data: any = rootState.Parcel.formData;
                const body = {
                    ...data,
                    packageDetails: {
                        ...data.packageDetails,
                        value: parseFloat(data.packageDetails.value),
                        // height: parseFloat(data.packageDetails.height),
                        // weight: parseFloat(data.packageDetails.weight),
                        // length: parseFloat(data.packageDetails.length),
                        width: parseFloat(data.packageDetails.width),
                    }
                }

                const res = await addParcel(body);
                if(res?.isSuccess){
                    dispatch.UI.setIsLoading(false);
                    dispatch.UI.setAlert({message: res?.message ?? 'Parcel added', type: 'success'});
                    dispatch.Parcel.resetFormData();
                    setTimeout(() => {
                        dispatch.Parcel.getBookedParcel({operator: '=='});
                    },1000);
                    return true;
                }
            }catch(err: any){
                const errMsg = !err.response ? 'Unable to add parcel' : err.response.data?.error;
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({message: errMsg, type:'error'});
                return false;
            }
        },
        async scheduleParcelBooking(payload: IScheduleBooking, rootState) {
            try{
                dispatch.UI.setIsLoading(true)
                const res = await scheduleParcelBooking(payload);
                if(res.isSuccess){
                    dispatch.UI.setIsLoading(false);
                    dispatch.UI.setAlert({message: res?.message ?? 'Schedule set', type: 'success'});
                    setTimeout(() => {
                        dispatch.Parcel.getBookedParcel({operator: '=='});
                    },1000);
                    return true;
                }
            }catch(err: any){
                const errMsg = !err.response ? 'Unable to add parcel' : err.response.data?.error;
                dispatch.UI.setIsLoading(false);
                dispatch.UI.setAlert({message: errMsg, type:'error'});
                return false;
            }
        },
        async generatePdf(payload, rootState) {
            try{
                dispatch.UI.setIsLoading(true)
                const ids = rootState.Parcel.selectedIds;
                if(ids.length < 1) {
                    dispatch.UI.setIsLoading(false)
                    return dispatch.UI.setAlert({message: 'Select atleast 1 booking', type: 'error'})
                }
                await generatePdf({parcelId: ids});
                dispatch.UI.setIsLoading(false)
            }catch(err){
                console.log(err)
                dispatch.UI.setIsLoading(false)
            }
        },
        async getBookedParcel(payload: IFilter, rootState) {
            try{
                const { operator } = payload;
                const customerId = rootState.User?.userInfo?.userId;
                const db = firebase.firestore();

                const querySnapshot: any | [] = await db.collection('parcel')
                    .where('status', operator, 'pending')
                    .get();

                const listData = querySnapshot?.docs?.map((doc) => {
                    const data = doc.data() as any;
                    return data;
                }).filter(parcel => parcel?.customer.id === customerId)
                dispatch.Parcel.setData({listData})
            }catch(err) {
                console.log("Error getting documents: ", err);
            }
        },
        async getOrderBreakdown(payload, rootState) {
            try{
                dispatch.Parcel.updateState({isLoading: true})
                const ids = rootState.Parcel.selectedIds;
                const res = await getOrderBreakdown({parcelId:ids});
                if(res.isSuccess){
                    dispatch.Parcel.updateState({orderBreakdown: res})
                    dispatch.Parcel.updateState({isLoading: false})
                }
            }catch(err){
                console.log(err)
                dispatch.Parcel.updateState({isLoading: false})
            }
        },
        async getAutocompleteSuggestions(text: string, rootState) {
            try{
                dispatch.Parcel.updateState({isLocationsLoading: true})
                const res = await getAutocompleteSuggestions(text);
                dispatch.Parcel.updateState({locations: res.predictions})
                dispatch.Parcel.updateState({isLocationsLoading: false})
            }catch(err){
                dispatch.Parcel.updateState({isLocationsLoading: false})
                console.log(err);
            }
        }
    }),
});

