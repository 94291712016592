import "./App.scss";
import "./globalScss/index.scss";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAlert } from "react-alert";
import {lazy} from 'react'
import { RootState, Dispatch } from "./store";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import firebase from "./Firebase";
import WebWorkers from "./components/WebWorkers"; 

const Login = lazy(() => import('./containers/Login/Login'));
const SharedLink = lazy(() => import('./containers/ShareLink/SharedLink'));

const LoginPin = lazy(() => import('./containers/AllEasyLogin/LoginPin'));
const AllEasySignup = lazy(() => import('./containers/AllEasySignUp/AllEasySignUp'));
const PinPage = lazy(() => import('./containers/AllEasySignUp/PinPage'));
const OTPPage = lazy(() => import('./containers/AllEasySignUp/OTPPage'));
const SignUp = lazy(() => import('./containers/SignUp/SignUp'));
const WalletLedger = lazy(() => import('./containers/WalletLedger/WalletLedger'));
const ForgetPassword = lazy(() => import('./containers/ForgetPassword/ForgetPassword'));
const SMS = lazy(() => import('./containers/ForgetPassword/SMS'));
const AuthRoute = lazy(() => import('./components/AuthRoute/AuthRoute'));
const Home = lazy(() => import('./containers/Home/Home'));
const Order = lazy(() => import('./containers/Order/Order'));
const OrderServices = lazy(() => import('./containers/OrderServices/OrderServices'));
const OrderDetails = lazy(() => import('./containers/OrderDetails/OrderDetailsPage'));
const TopUp = lazy(() => import('./containers/TopUp/TopUp'));
const TopUpWallet = lazy(() => import('./containers/TopUp/TopUpWallet'));
const TopUpSummary = lazy(() => import('./containers/TopUp/TopUpSummary'));
const OrderConfirmation = lazy(() => import('./containers/OrderConfirmation/OrderConfirmation'));
const OrderList = lazy(() => import('./containers/OrderList/OrderList'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));
const ChangePassword = lazy(() => import('./containers/ChangePassword/ChangePassword'));
const Logout = lazy(() => import('./containers/Logout/Logout'));
const ParcelDelivery = lazy(() => import('./containers/ParcelDelivery/Home/ParcelDelivery'));
const ParcelOrderList = lazy(() => import('./containers/ParcelDelivery/OrderList/OrderList')); 
const ParcelOrderListView = lazy(() => import('./containers/ParcelDelivery/View/View')); 
const Account = lazy(() => import('./containers/Account/Account'));  
const AddressBook = lazy(() => import('./containers/Account/Content/AddressBook')); 

const Loader = lazy(() => import('./components/Loader/Loader'));
const SideMenu = lazy(() => import('./components/SideMenu/SideMenu'));
// const NavHeader = lazy(() => import('./components/NavHeader/NavHeader'));

require('dotenv').config()


let PRIORITY_FEE_UNSUBSCRIBE: any = null; 

// component app
const App = () => {
  const alertHook = useAlert();
  const dispatch = useDispatch<Dispatch>();

  const isUserLoggedIn = useSelector(
    (state: RootState) => state.User.isUserLoggedIn
  );

  const { isLoading, alert } = useSelector((state: RootState) => state.UI);
  const { message, type, alertWatcher } = alert;



  useEffect(() => {
    // checks alert watcher if it is update just two sec ago
    let isLessThanTwoSecs = moment(alertWatcher).isAfter(
      moment(moment.now()).subtract(0.5, "seconds")
    );

    if (message && isLessThanTwoSecs) {
      alertHook.show(message, { type, timeout: 3000 });
    }

    // eslint-disable-next-line
  }, [alertWatcher]);

  return (
    <WebWorkers>
      <Router> 
        {isUserLoggedIn ? (<>
          <SideMenu/>
          {/* <NavHeader/>  */}
        </>) : null}
        <Switch> 
          <Route exact path="/" component={Login} /> 
          <Route exact path="/logout" component={Logout} /> 
          <Route exact path="/alleasysignup" component={AllEasySignup} /> 
          <Route exact path="/signup/otppage" component={OTPPage} />
          <Route exact path="/signup/pinpage" component={PinPage} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/sms" component={SMS} />
          <Route path="/login/:authId" component={Login} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/sms" component={SMS} />  
          <Route exact path="/sharedlink/:referenceNumber?/:shareAuth?" component={SharedLink} />
          <Route exact path="/changepassword" component={ChangePassword} />

          <AuthRoute exact path="/alleasy/connect" component={LoginPin} />
          <AuthRoute path="/home" component={Home} />

          <AuthRoute path="/order" component={Order} />
          <AuthRoute path="/order-details/:referenceNumber" component={OrderDetails} />
          <AuthRoute path="/order-services" component={OrderServices} />
          <AuthRoute path="/order-confirmation" component={OrderConfirmation} />
          <AuthRoute path="/order-list/:referenceNumber?" component={OrderList} /> 

          <AuthRoute path="/topup" component={TopUp} />
          <AuthRoute path="/wallet" component={WalletLedger} />
          <AuthRoute path="/topupwallet" component={TopUpWallet} />
          <AuthRoute path="/topupsummary" component={TopUpSummary} />

          <AuthRoute path="/parcel-delivery" component={ParcelDelivery} />
          <AuthRoute path="/parcel/order-list" component={ParcelOrderList} /> 
          <AuthRoute path="/parcel/order-history/view/:id" component={ParcelOrderListView} /> 

          <AuthRoute path="/acct" component={Account} /> 
          <AuthRoute path="/address-book" component={AddressBook} /> 
          
          <Route component={NotFound} />
        </Switch>
      </Router>
      {isLoading ? <Loader ultra={true} /> : null}
    </WebWorkers>
  );
};

export default App;
