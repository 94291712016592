import { post, get } from "../core";

export const validateLocation = async (body: any) => {
  try {
    const res = await post('/partial-load/validate-locations', body)
  } catch (err) {
    throw err;
  }
}

export const getFare = async (body: any) => {
  try {
    let res = await post(`/express-orders/quote`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPartialFare = async (body: any) => {
  try {
    let res = await post(`/partial-load/quote`, body);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getExpressFareSetting = async () => {
  try {
    let res = await get(`/express-rate-card`);
    return res.data.data;
  } catch (err) {
    throw err;
  }
};

