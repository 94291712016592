import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { checkLocationIsAllowed } from "../common/utils";
import { ILocations, ILocation } from "../types/ILocations";
import moment from "moment";
import { getAddressNameByLatLng } from "../common/api";

export interface ILocationsState {
  locations: ILocations;
  pickup: ILocation;
  stops: ILocations | [];
  dropOff: ILocation;
  targetLocationIndex: number | null;
}

interface ILatLngPayload {
  lat: number;
  lng: number;
  index: number;
}

export const locationsInitialState = {
  locations: [
    {
      locationAddress: "",
      contactName: "",
      lat: 0,
      lng: 0,
      locationDetails: "",
      contactPhone: "",
      showPopOver: false
    },
    {
      locationAddress: "",
      contactName: "",
      lat: 0,
      lng: 0,
      locationDetails: "",
      // deliveryInstruction: "",
      // itemCategory: "",
      contactPhone: "",
      showPopOver: false
    }
  ],
  pickup: {
    locationAddress: "",
    contactName: "",
    lat: 0,
    lng: 0,
    deliveryInstruction: "",
    itemCategory: "",
    locationDetails: "",
    contactPhone: "",
    showPopOver: false
  },
  stops: [],
  dropOff: {
    locationAddress: "",
    deliveryInstruction: "",
    itemCategory: "",
    contactName: "",
    lat: 0,
    lng: 0,
    locationDetails: "",
    contactPhone: "",
    showPopOver: false
  },
  targetLocationIndex: null
} as ILocationsState;

export const Locations = createModel<RootModel>()({
  state: {
    ...locationsInitialState,
    locations: locationsInitialState.locations.map((location) => ({
      ...location
    }))
  },
  reducers: {
    resetState(state) {
      return {
        ...state,
        ...locationsInitialState,
        locations: locationsInitialState.locations.map((location) => ({
          ...location
        }))
      };
    },
    updateState(state, newState: Partial<ILocationsState>) {

      let locationValidation = (location:ILocation) => {

        return (location.locationAddress !== "" 
                && location.contactName !== ""
                && location.contactPhone !== ""
        ) || (location.lat === 0)
      }

      if(newState.locations){

        let locations = newState.locations.map((location, key) => ({...location, 
          sequenceNo: key,
          valid: locationValidation(location),
          location: {
            lat: location.lat,
            lng: location.lng,
            address: location.locationAddress,
            details: location.locationDetails,
          }}));


        let pickup = locations[0];
        let stops:any = locations?.slice(1);
        let dropOff = stops?.pop();
      
        stops = stops.length > 0 ? stops : []; 

        
        newState.locations = locations

        newState = { ...newState, 
          pickup, dropOff, stops 
        }
      }
      
      return { ...state, ...newState};
    },
    updateLocation(state, newState: Partial<any>){
      return {
        ...state,
        ...newState
      }
    }
  },
  effects: (dispatch) => ({ 
    async setLatLng(payload: ILatLngPayload, state) {
      const { lat, lng, index } = payload;
      const { locations } = state.Locations;

      try {
        let res = await getAddressNameByLatLng(lat, lng);
        if (res && res.results.length > 0) {
          let formattedAddress = res.results[0].formatted_address;

          if (locations[index].lat !== lat && locations[index].lng !== lng) {
            dispatch.Order.updateState({ isOptimizeLocation: false });
          }

          let newDestinations = locations.map((location, i) => {
            if (index === i) {
              location.locationAddress = formattedAddress;
              location.lat = lat;
              location.lng = lng;
            }
            return { ...location };
          });

          dispatch.Locations.updateState({ 
            locations: [...newDestinations]
          });
          dispatch.Order.setLastUpdate(moment.now());
        }
      } catch (err) {
        console.log(err);
      }
    }
  })
});
