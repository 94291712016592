import axios from "axios";
import moment from "moment";
import { post, get, put } from "../core";
const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD as string;

export const getDistanceETA = async (fromLat: number, fromLng: number, toLat: number, toLng: number,) => {
  try {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/user-maps/shortest-distance/json?origin=${fromLat},${fromLng}&destination=${toLat},${toLng}&alternatives=true`,
      {
        auth: {
          username: btoa(moment.now().toString()),
          password: process.env.REACT_APP_BASIC_AUTH_PASSWORD as string,
        },
      }
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAddressNameByLatLng = async (lat: number, lng: number) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/maps/geocode?latlng=${lat},${lng}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};


export const getByLatLngAddressName = async (address: string) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/maps/getlatlng/${address}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getAutocompleteSuggestions = async (
  input: string
) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/maps/autocomplete/${input}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const getPlaceDetails = async (placeId: string) => {
  try {
    let res = await axios.get(`${process.env.REACT_APP_API_URL}/maps/place-details/${placeId}`);
    return res.data;
  } catch (err) {
    throw err;
  }
};


export const addTripPriorityFee = async (tripId: string, amount: number) => {
  try {
    let res = await post(
      `${process.env.REACT_APP_CLOUDFUNCTION_URL}/v1/trip/priority_fee`,
      { tripId, amount },
      { source: "customer" }
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};
