import { Models } from "@rematch/core";

import { User } from "./UserModel";
import { Order } from "./OrderModel";
import { Locations } from "./LocationsModel";
import { OrderConfirmation } from "./OrderConfirmation";
import { OrderList } from "./OrderList";
import { UI } from "./UIModel";
import { Wallet } from "./WalletModel";
import { Parcel } from "./parcelModel"
export interface RootModel extends Models<RootModel> {
  User: typeof User;
  Order: typeof Order;
  Locations: typeof Locations;
  OrderConfirmation: typeof OrderConfirmation;
  OrderList: typeof OrderList;
  UI: typeof UI;
  Wallet: typeof Wallet;
  Parcel: typeof Parcel;
}

export const models: RootModel = {
  User,
  Order,
  Locations,
  OrderConfirmation,
  OrderList,
  UI,
  Wallet,
  Parcel
};
