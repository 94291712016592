import { put, get, post } from "../core";

export const requestOrder = async (data: any) => {
  try {
    let res = await post(`/express-orders`, data);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const requestInterislandOrder = async (data: any) => {
  try {
    let res = await post(`/interisland-orders`, data);
    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const sharelink = async (order: any) => {
  try{ 
    let shareAuth = `${order.shareAuth}`; 
    console.log(shareAuth)
    const res = await post(`/public/authentication`, {shareAuth})
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}