import { createModel } from "@rematch/core";
import { RootModel } from ".";
import { getRecentTransactions, getWalletBalance, requestWalletWithdrawal } from "../common/api";
export interface State {
  balance: number;
  walletTransactions: Array<any>;
  isLoading: boolean;
}

interface IRequest {
  amount: Number,
  bank: string
}

const initialState = {
  balance: 0,
  walletTransactions: [],
  isLoading: false
} as State

export const Wallet = createModel<RootModel>()({

  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState }
    },
    setNewState(state, newState: Partial<State>) {
      return { ...state, ...newState };
    },
  },
  effects: (dispatch) => ({
    /**
     * @name getWalletBalance
     * @desc get user's wallet balance
     */
    async getWalletBalance(payload, rootState) {
      let balance
      try {
        dispatch.Wallet.setNewState({ isLoading: true })
        balance = await getWalletBalance();
      } catch (err) {
        throw err
      } finally {
        dispatch.Wallet.setNewState({ balance: balance, isLoading: false })
        dispatch.Wallet.setNewState({ isLoading: false })
      }
    },
    /**
     * @name getWalletTransactions
     * @desc get user's wallet transactions
     */
    async getRecentTransactions() {
      let transactions
      try {
        dispatch.Wallet.setNewState({ isLoading: true })
        const res = await getRecentTransactions();
        transactions = res.data
      } catch (err) {
        throw err
      } finally {
        dispatch.Wallet.setNewState({ walletTransactions: transactions, isLoading: false })
        dispatch.Wallet.setNewState({ isLoading: false })
      }
    },
    /**
     * @name requestWithdrawal
     * @desc request wallet withdrawal
     */
     async requestWithdrawal(body: IRequest) {
      try {
        dispatch.UI.setIsLoading(true);
        const res = await requestWalletWithdrawal(body);
        if(res.isSuccess){
          dispatch.Wallet.getWalletBalance();
          dispatch.Wallet.getRecentTransactions();
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({ message: 'Request sent.', type: "success"})
          return true
        }
      } catch (err) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({ message: 'Request sent.', type: "success"})
          return false
      }
    },
  }),
});
