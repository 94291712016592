import moment, { unix } from 'moment';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
	return new URLSearchParams(useLocation().search)
  }

export const getUrl = () => {
	if (process.env.NODE_ENV === 'development') {
		const link = `http://localhost:3000/`
		return link;
	} else {
		const link = `${process.env.REACT_APP_BASE_URL}/`
		return link;
	}
}

const toRad = (Value) => {
	return Value * Math.PI / 180;
}

export const calcDistanceBetDriverAndPickupPoint = (lat1, lon1, lat2, lon2) => {
	const R = 6371; // km
	const dLat = toRad(lat2 - lat1);
	const dLon = toRad(lon2 - lon1);
	const latOne = toRad(lat1);
	const latTwo = toRad(lat2);

	const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(latOne) * Math.cos(latTwo);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c;
	return Math.round(d);
}

export const isNumber = (value: any) => {
	return !isNaN(Number(value));
}

export const currencyCommaFormat = (value: any, decimal = 0, hasPesoSign: boolean = false) => {
	if (isNumber(value)) {
		return Number(value).toFixed(decimal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	} else {
		return `${hasPesoSign ? '₱ ' : ''}${String(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}
};

interface DateTimeProps {
	date: string;
	time: string;
}

const dateFormatToday = 'MMM DD [(Today)]', dateFormat = 'MMM DD (ddd)', timeDefaultFormat = 'h:mm A';

export const formatDeliveryDate = (date: any, dateFromat?: string, timeFormat?: string) => {
	let defaultFormat = dateFromat ? dateFromat : dateFormat;
	let defaultTodayFormat = dateFromat ? dateFromat : dateFormatToday;
	timeFormat = timeFormat ? timeFormat : timeDefaultFormat;

	if (!date && date === '') return '';

	const value = {
		date: (moment(date).isSame(moment(), 'date')) ? moment(date).format(defaultTodayFormat) : moment(date).format(defaultFormat),
		time: moment(date).format(timeFormat)
	} as DateTimeProps;

	return value;
}

export const formatTripDateByUnix = (unixValue: number) => {

	if (unixValue) {
		const myMoment = unix(unixValue);
		const isToday = myMoment.isSame(moment(), 'day');

		const value = {
			date: myMoment.format(isToday ? dateFormatToday : dateFormat),
			time: myMoment.format(timeDefaultFormat)
		} as DateTimeProps;
		return value;
	}
	return null;
}


export const getVehicleLabel = (vehicle: string) => {
	if (vehicle === "motorcycle") return "Motorcycle";
	else if (vehicle === "mpv300") return "MPV300";
	else if (vehicle === "mpv600") return "MPV600";
	else if (vehicle === "lt1000") return "LT1000";


	return vehicle;
}

export const getTripTypeLabel = (type: number) => {
	if (type === 1) return "Single Trip";
	else if (type === 2) return "Round Trip";

	return "";
}

export const getTripScheduleTypeLabel = (type: number) => {
	if (type === 1) return "Scheduled";
	else if (type === 2) return "Immediate";

	return "";
}

export const rowCount = (props: any, isLowerDevice?: boolean) => {

	if (!isLowerDevice) {
		if (props?.value?.length > 0 && props?.value.length < 55) {
			return 1;
		}
		if (props?.value?.length >= 55 && props?.value?.length < 110) {
			return 2;
		}
		if (props?.value.length >= 110 && props?.value.length < 145) {
			return 3;
		}
		if (props?.value.length >= 145) {
			return 4;
		}
		return 1;
	}
	else {
		if (props?.value?.length > 0 && props?.value.length < 30) {
			return 1;
		}
		if (props?.value?.length >= 30 && props?.value.length <= 65) {
			return 2;
		}
		if (props?.value?.length > 65 && props?.value.length < 100) {
			return 3;
		}
		if (props?.value.length >= 100) {
			return 4;
		}
		return 1;
	}
}

export const onlyNumberKey = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return evt.preventDefault();
	}
	return true;
}

export const onlyNumberKeyAndDot = (evt: any) => {
	const ASCIICode = (evt.which) ? evt.which : evt.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		if (ASCIICode === 46) {
			return true
		}
		return evt.preventDefault();
	}
	return true;
}