import { createModel } from "@rematch/core";
import { TRIP_STATUS } from "../constants";
import { RootModel } from ".";
import {
  IDataOrders,
  IDataOrder,
  IOrderList,
  IOrderFilters
} from "../types/IDataOrders";

let data: IDataOrder = {
  data: [],
  currentPage: 1
};

const initialState = {
  page: 1,
  maxPage: 0,
  isLoading: false,
  dataOrder: data,
  options: [""],
  orderFilters: null,
} as IOrderList;

export const OrderList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState };
    },
    setDataOrder(state, payload: IDataOrder) {
      state.dataOrder = payload;
      return { ...state };
    },
    setState(state, newState: Partial<IOrderList>) {
      return { ...state, ...newState };
    },
    handleNext: (_) => ({ ..._, page: _.page + 1 }),
    handlePrevious: (_) => ({ ..._, page: _.page - 1 }),
    setIsLoading: (_, isLoading: boolean) => ({ ..._, isLoading }),
    setMaxPage: (_, maxPage: number) => ({ ..._, maxPage }),
    setFilters(state, filters: IOrderFilters) {
      const orderFilters = { ...state?.orderFilters, ...filters };
      return { ...state, orderFilters };
    },
  },
  effects: (dispatch) => ({
  
  })
});
