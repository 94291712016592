import { createModel } from "@rematch/core";
import moment from "moment";
import { IRegion, IDestinations, ILatLng } from "../types";
import { RootModel } from ".";
import {
  requestInterislandOrder,
  requestOrder,
} from "../common/api/endpoints/order";
import { IVehicles } from "../types/IVehicle";
import { getFare, getPartialFare, validateLocation } from "../common/api/endpoints/price";
import {
  getAutocompleteSuggestions
} from "../common/api";
import firebase from "../Firebase";
import { PAYMENT_METHODS } from "../constants";
import { ILocation, ILocations } from "../types/ILocations";
import { useQuery } from "../common/utils";

interface ISetAddOnsIsCheckedPayload {
  target: string;
  value: boolean;
}

interface ISetAddOnsCountPayload {
  target: string;
  value: number;
}

interface IContainers {
  vehicleType?: string;
  vehicleId?: number;
  isSelected?: boolean;
  vehicleDescription?: string;
  type?: string;
}

interface ISetUpAddOnsPayload extends ILatLng {
  selectedIndex: number;
}

interface ISetSubAddOnsIsSelected extends ISetAddOnsIsCheckedPayload {
  categoryValue: string;
}

export interface DriverDetailsProps {
  driverId?: string | null;
  bikerId?: string | null;
  driverName?: string | null;
}

type MapType = "GOOGLE" | "LEAFLET";
type MapPermission = "READ" | "WRITE";

export interface State {
  addOns: any;
  description: any;
  isEditMode: boolean;
  isModalShown: boolean;
  isOptimizeLocation: boolean;
  lastUpdate: number;
  optimizeBasedLocations: ILocations | null;
  recentSources: ILocations;
  recentDestinations: ILocations;
  schedule: string | null;
  scheduleType: "1" | "2" | "3";
  days: number;
  currentStep: number;
  totalFee: number | null;
  vehicles: any;
  containers: any;
  loadType: string;
  // Trip Type
  // 1 - one way
  // 2 - round trip
  tripType: 1 | 2;
  quantity: number;
  voucher: number | null;
  voucherCode: string | null;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;
  reference: string | null; // reference generated when getting trip fare
  itemDescription: string | null;
  deliveryInstruction: string | null;
  isRequestOrderLoading: boolean;
  requestOrderResponse: any;
  isFareDetailsLoading: boolean;
  orderModified: boolean; // Attribute used to distinguish if need to recalculate fare
  isValidatePromoCodeLoading: boolean;
  isRequestingAddressByLatLng: boolean;
  isAddOnsLoading: boolean;
  isLoading: boolean;
  isConsolidated: boolean;
  promoCode: string | null;
  isValidPromoCode: number | null;
  vehicleType: string | null;
  /**
   * Payment Modes
   * 1 - Cash
   * 2 - GetAll
   */
  paymentMethod: string;
  billTo?: string;
  isValidateDriverIdLoading?: boolean;

  /**
   * Validate driver status
   * 0 | null - no processing yet
   * 1 - success
   * 2 - fail
   * 3 - not found
   */
  validateDriverStatus?: number;
  validateDriverError?: string;
  driverDetails?: DriverDetailsProps | null;

  /*
    ALL: 1,
    FLEET: 2,
    ASSIGN: 3
  */
  isSavingLocationToFavorite: boolean;
  merchantReference?: string | null;
  mapType?: MapType;
  mapPermission?: MapPermission;
  orderDetails: any;
  RFPSequence: number;
}

export const initialState = {
  description: {
    motorcycle: {
      name: 'Motorcyle',
      description: '20kg'
    },
    mpv300: {
      name: 'MPV300',
      description: '300kg'
    },
    mpv600: {
      name: 'MPV600',
      description: '600kg'
    },
    lt1000: {
      name: 'LT1000',
      description: '1000kg'
    }
  },
  addOns: {
    insulatedBag: {
      name: "Insulated Bag",
      isChecked: false,
      price: 0
    },
    // premiumService: {
    //   name: "Premium Service ₱50",
    //   isChecked: false,
    //   price: 50
    // },
    // premiumService100: {
    //   name: "Premium Service ₱100",
    //   isChecked: false,
    //   price: 100
    // },
    // cashHandling: {
    //   name: "Cash Handling",
    //   isChecked: false,
    //   description: '*COD-Max of ₱5,000',
    //   price: 30
    // }, 
    remittance: {
      name: "Remittance",
      isChecked: false,
      price: 30
    },
    queueingService: {
      name: "Queuing Service",
      isChecked: false,
      price: 30
    },
    purchaseService: {
      name: "Purchase Service",
      isChecked: false,
      price: 30
    },
    overweightHandling: {
      name: "Driver Carries",
      isChecked: false,
      price: 30
    },
    driverTip: {
      name: "Driver Tip",
      isChecked: false,
      price: 50
    },
    standardService: {
      name: "Standard Service",
      isChecked: false,
      details: 'The Economy service includes the fuel, use of the vehicle, and service from the driver to transport your goods according to your designated route.'
    },
    specialHelp: {
      name: "Special Help",
      isChecked: false,
      details: 'Loading and unloading  is already included in your fare. Only select this option when you require your driver to work above and beyond this.'
    },
    extraHelp: {
      name: "Extra Help",
      isChecked: false,
      details: 'Select this option if you would like your driver to bring extra helpers to your booking. The prices below are stated per extra helper.'
    },
    customerStaff: {
      name: "Customer Staff",
      count: 1,
      isChecked: false,
      details: 'Select this option to send your own staff to ride with our driver to secure or administer your goods.'
    },
    pushCart: {
      name: "Push Cart",
      isChecked: false,
      details: 'Toggle this extra service if you require the crew of your booking to bring a push cart to facilitate the handling of your cargo.'
    },
    // extraSpace: {
    //   name: "Extra Space",
    //   isChecked: false,
    // },
    safetyGloves: {
      name: "Safety Gloves",
      isChecked: false,
      details: 'Safety gloves are encouraged if the items being carried are heavy or have sharp edges. Some examples of this would be pieces of heavy equipment or large pieces of glass.'
    },
    hardHat: {
      name: "Hard Hat",
      isChecked: false,
      details: 'Hard hat is usually required in areas such as construction sites, manufacturing plants and warehouses for the reasons stated above.'
    },
  },
  isEditMode: false,
  isModalShown: false,
  isOneWay: true,
  isOptimizeLocation: false,
  isRequestingAddressByLatLng: false,
  isSavingLocationToFavorite: false,
  lastUpdate: moment.now(),
  optimizedDestinations: [],
  recentDestinations: [],
  recentSources: [],
  vehicles: [
    {
      vehicleType: "motorcycle",
      vehicleId: 0,
      isSelected: true,
      vehicleDescription: "Motorcycle",
      description: "200kg",
      availableAddOns: ["standardService", "specialHelp", "extraHelp", "customerStaff", "extraSpace", "safetyGloves", "hardHat"],
      location: "Manila",
      details: 'Economy can hold 200kg of cargo. Economy is the most affordable delivery vehicle in our platform.',
      type: 'wholeVehicle'
    },
    {
      vehicleType: "economy",
      vehicleId: 1,
      isSelected: true,
      vehicleDescription: "Economy",
      description: "200kg",
      availableAddOns: ["standardService", "specialHelp", "extraHelp", "customerStaff", "extraSpace", "safetyGloves", "hardHat"],
      location: "Manila",
      details: 'Economy can hold 200kg of cargo. Economy is the most affordable delivery vehicle in our platform.',
      type: 'wholeVehicle'
    },
    {
      vehicleType: "light_van",
      vehicleId: 2,
      vehicleDescription: "Light Van",
      availableAddOns: ["standardService", "specialHelp", "extraHelp", "customerStaff", "extraSpace", "safetyGloves", "hardHat"],
      description: "600kg",
      location: "Manila",
      details: 'Light-Van can hold 600kg of cargo. Its a little bit smaller and more variable in terms of vehicle model and cargo.',
      type: 'wholeVehicle'
    },
    {
      vehicleType: "l300",
      vehicleId: 3,
      vehicleDescription: "L300/ Van",
      availableAddOns: ["standardService", "specialHelp", "extraHelp", "customerStaff", "extraSpace", "safetyGloves", "hardHat"],
      description: "1000kg",
      location: "Manila",
      details: 'L300/ Van can hold 1000kg of cargo. The L300 is the most common delivery van model in our platform.',
      type: 'wholeVehicle'
    },
    {
      vehicleType: "closed_van",
      vehicleId: 4,
      vehicleDescription: "Closed Van",
      availableAddOns: ["standardService", "specialHelp", "extraHelp", "customerStaff", "extraSpace", "safetyGloves", "hardHat"],
      description: "2000kg",
      location: "Manila",
      details: 'Closed-Van can hold 2000kg of cargo. Closed Van is a light truck with an aluminum box cargo space.',
      type: 'wholeVehicle'
    },
  ],
  containers: [
    {
      vehicleType: 'bag',
      vehicleId: 1,
      isSelected: true,
      vehicleDescription: 'Bag',
      type: 'partialLoad'
    },
    {
      vehicleType: 'box',
      vehicleId: 2,
      vehicleDescription: 'Box',
      type: 'partialLoad'
    },
    {
      vehicleType: 'palette',
      vehicleId: 3,
      vehicleDescription: 'Pallete',
      type: 'partialLoad'
    },
    {
      vehicleType: 'crate',
      vehicleId: 4,
      vehicleDescription: 'Crate',
      type: 'partialLoad'
    },
    {
      vehicleType: 'drum',
      vehicleId: 5,
      vehicleDescription: 'Drum',
      type: 'partialLoad'
    },
    {
      vehicleType: 'roll',
      vehicleId: 6,
      vehicleDescription: 'Roll',
      type: 'partialLoad'
    },
    {
      vehicleType: 'other',
      vehicleId: 7,
      vehicleDescription: 'Other',
      type: 'partialLoad'
    },
  ],
  loadType: 'wholeVehicle',
  scheduleType: "2",
  days: 1,
  currentStep: 1,
  vehicleType: "",
  schedule: null,
  tripType: 1,
  serviceType: 1,
  totalFee: null,
  quantity: 1,
  voucher: null,
  voucherCode: null,
  length: null,
  width: null,
  height: null,
  weight: null,
  reference: null,
  itemDescription: null,
  deliveryInstruction: null,
  isRequestOrderLoading: false,
  requestOrderResponse: null,
  isFareDetailsLoading: false,
  orderDetails: null,
  orderModified: false,
  isValidatePromoCodeLoading: false,
  isValidPromoCode: null,
  isAddOnsLoading: false,
  isLoading: false,
  isConsolidated: false,
  optimizeBasedLocations: null,
  promoCode: null,
  paymentMethod: '',
  billTo: '',
  driverDetails: null,
  merchantReference: null,
  mapType: "GOOGLE",
  mapPermission: "WRITE",
  RFPSequence: 0
} as State;

export const Order = createModel<RootModel>()({
  state: initialState,
  reducers: {
    /**
     * @name resetState
     * @desc Reset order attributes
     */
    resetState(state) {
      // making sure that pop over were close
      return { ...state, ...initialState };
    },
    /**
     * @name resetAddOns
     * @desc Sets addOns to its original state
     */
    resetAddOns(state) {
      return {
        ...state,
        addOns: { ...initialState.addOns }
      };
    },
    /**
     * @name setAddOnsIsChecked
     * @desc Sets isChecked attributes of addOns to true or false
     * @param {object} payload that has attributes of payload.target and payload.value
     */
    setAddOnsIsChecked(state, payload: ISetAddOnsIsCheckedPayload) {
      const { target, value } = payload;
      return {
        ...state,
        addOns: {
          ...state.addOns,
          [target]: { ...state.addOns[target], isChecked: value }
        },
        orderModified: true
      };
    },
    setAddOnsCount(state, payload: ISetAddOnsCountPayload) {
      const { target, value } = payload;
      return {
        ...state,
        addOns: {
          ...state.addOns,
          [target]: { ...state.addOns[target], count: value }
        },
        orderModified: true
      };
    },
    /**
     * @name setSubAddOnsIsSelected
     * @desc Sets isSelected attributes of add ons subcategory to true or false
     * @param {object} payload that has attributes of payload.target, payload.categoryValue and payload.value
     */
    setSubAddOnsIsSelected(state, payload: ISetSubAddOnsIsSelected) {
      const { target, categoryValue, value } = payload;

      return {
        ...state,
        addOns: {
          ...state.addOns,
          [target]: {
            ...state.addOns[target],
            subAddOns: state.addOns[target].subAddOns.map((elem: any) => {
              if (elem.value === categoryValue) {
                elem.isSelected = value;
              } else {
                elem.isSelected = !value;
              }
              return elem;
            })
          }
        },
        orderModified: true
      };
    },
    /**
     * @name setTripType
     * @desc Sets state.tripType to be 1 | 2
     * @param {1|2} payload { 1 | 2 }
     */
    setTripType(state, payload: 1 | 2) {
      return { ...state, tripType: payload, orderModified: true };
    },
    setIsEditMode(state, payload: boolean) {
      return { ...state, isEditMode: payload };
    },
    setIsModalShown(state, payload: boolean) {
      return { ...state, isModalShown: payload };
    },
    setDestinations(state, payload: IDestinations) {
      return { ...state, destinations: payload };
    },
    setIsOptimizedLocation(state, payload: boolean) {
      return { ...state, isOptimizeLocation: payload, orderModified: true };
    },
    setLastUpdate(state, payload: number) {
      return { ...state, lastUpdate: payload };
    },
    setSchedule(state, payload: string) {
      return { ...state, schedule: payload, orderModified: true };
    },
    /**
     * @name setVehicles
     * @desc Sets vehicles state
     * @param {IVehicles} payload
     *
     **/
    setVehicles(state, payload: IVehicles) {
      return { ...state, vehicles: [...payload], orderModified: true };
    },
    setContainers(state, payload: IVehicles) {
      return { ...state, containers: [...payload], orderModified: true };
    },
    toggleMap(state) {
      let mapType: MapType;
      if (state.mapType === "LEAFLET") mapType = "GOOGLE";
      else mapType = "GOOGLE";

      return { ...state, mapType };
    },
    setMapPermission(state, permission: MapPermission) {
      return { ...state, mapPermission: permission };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState };
    }
  },
  effects: (dispatch) => ({
    /**
     * @name calculateFare
     * @desc get trip price based on the set parameters
     *
     */
    async calculateFare(payload, rootState) {
      if (rootState.Order.isFareDetailsLoading) return; // Prevent from multiple loading of http

      let response, totalFee, reference, fare, token, request;
      const selectedVehicle = (rootState.Order.vehicles || []).find((vehicle: any) => vehicle?.isSelected);
      const selectedContainer = (rootState.Order.containers || []).find((vehicle: any) => vehicle?.isSelected);

      const { locations, targetLocationIndex, pickup, stops, dropOff } = rootState.Locations;
      const { loadType, scheduleType, schedule, addOns, weight, height, width, length, quantity, days, orderDetails, isConsolidated } = rootState.Order;

      if (scheduleType === '3' && days < 1) return;

      if (rootState.Locations?.dropOff?.lat === 0 && scheduleType !== '3') return;

      try {
        dispatch.Order.updateState({ isFareDetailsLoading: true });

        if (loadType === 'wholeVehicle') {
          request = {
            vehicleType: selectedVehicle.vehicleType,
            orderType: scheduleType === '1' ? 'scheduled' : scheduleType === '2' ? 'immediate' : 'full day',
            scheduleStartTime: ['1', '3']?.includes(scheduleType) ? moment(schedule).format('YYYY-MM-DD HH:mm:ss') : null,
            pickup: pickup,
            stops: [...stops, dropOff],
            options: Object.keys(addOns).filter((key) => addOns[key].isChecked),
            customerStaffCount: addOns.customerStaff.isChecked ? Number(addOns.customerStaff.count) : Number(0),
            isConsolidated
          };

          if (scheduleType === '3') {
            request.days = days > 1 ? days : 1;
          }

          response = await getFare(request);
        } else {
          request = {
            loadType: selectedContainer.vehicleType,
            orderType: ['1', '3']?.includes(scheduleType) ? 'scheduled' : 'immediate',
            scheduleStartTime: ['1', '3']?.includes(scheduleType) ? moment(schedule).format('YYYY-MM-DD HH:mm:ss') : null,
            pickup: pickup,
            vehicleType: 'economy',
            stops: [...stops, dropOff],
            weight,
            height,
            width,
            length,
            quantity,
            isConsolidated: true
          };
          if ([weight, height, length, width].includes(null)) {
            response = await validateLocation(request);
          } else {
            response = await getPartialFare(request);
          }
        }

        totalFee = response?.fare.total;
        reference = response?.token;
      } catch (err: any) {
        const pathname = window.location.pathname;
        if (pathname !== '/order-services') {
          let newLocations = locations;
          const targetIndex = Number(targetLocationIndex);
          if (locations[targetIndex]) {
            newLocations[targetIndex] = locations[targetIndex];
            newLocations[targetIndex].locationAddress = "";
            newLocations[targetIndex].lat = 0;
            newLocations[targetIndex].lng = 0;
          }
          dispatch.Locations.updateState({ locations: [...newLocations] });
        }
        const errMsg = err?.response?.data?.error ?? 'Unable to pin address'
        dispatch.UI.setAlert({ message: errMsg, type: "error" });
      } finally {
        dispatch.Order.updateState({
          isFareDetailsLoading: false,
          totalFee,
          reference,
          orderModified: false,
          orderDetails: { ...response, payload: loadType === 'wholeVehicle' ? request : { ...request, volWeight: response?.fare?.volWeight ?? 0 } }
        });
      }
    },
    /**
     * @name requestOrder
     * @desc send request for order
     *
     */
    async requestOrder(payload, rootState) {
      // @ts-ignore
      let userInfo = {
        ...rootState.User.userInfo
      };
      dispatch.User.updateState({
        // @ts-ignore
        userInfo
      });

      let recentDestinations = [...rootState.Order.recentDestinations];
      let recentSources = [...rootState.Order.recentSources];

      if (rootState.Order.isRequestOrderLoading) return; // Prevent from multiple loading of http


      dispatch.Order.updateState({
        // merchantReference: null,
        isRequestOrderLoading: true
      });

      const { scheduleType, orderDetails, itemDescription, deliveryInstruction, paymentMethod, billTo, days, RFPSequence, loadType } = rootState.Order;
      const { pickup, stops, dropOff }: any = rootState.Locations;
      let locations = [pickup, ...stops, dropOff];

      locations = locations?.map(loc => {
        loc.isReliableFP = loc.sequenceNo === RFPSequence;
        return loc;
      })

      let response;
      try {
        let body = {
          ...orderDetails,
          pickup: locations[0],
          stops: locations.slice(1),
          itemDescription: itemDescription,
          notes: deliveryInstruction,
          paymentMethod: paymentMethod,
          billTo,
        };

        delete body.payload.pickup
        delete body.payload.stops

        if (orderDetails?.type !== 'INTERISLAND') {
          delete body.warehouseA
          delete body.warehouseB
        }

        if (scheduleType === '3') {
          delete body.stops
          delete body.payload.days
          body.days = days
        }

        if (orderDetails?.type === 'INTERISLAND') {
          response = await requestInterislandOrder(body);
        } else {
          response = await requestOrder(body);
        }

        dispatch.Order.resetState();
        dispatch.Order.updateState({
          recentSources,
          recentDestinations
        });
        dispatch.Locations.resetState();
        dispatch.OrderConfirmation.resetState();
        return { isSuccess: true };
      } catch (err: any) {
        const errMsg = err?.response?.data?.error ?? 'Unable to pin address'
        dispatch.UI.setAlert({ message: errMsg, type: "error" });
        response = err?.response?.data;
      } finally {
        // console.log("requestOrderResponse: ", response);
        dispatch.Order.updateState({
          // merchantReference: null,
          isRequestOrderLoading: false,
          requestOrderResponse: response
        });
      }
    },

  })
});
