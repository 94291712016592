/**
 * @name validateEmail
 * @description check if email is valid
 * @param {string} param
 * @return boolean
 */

export const validatePhone = (contactPhone: string) => {
  const re = /9[0-9]{9}/;
  if (re.test(contactPhone)) {
    if (contactPhone.length <= 10) {
      return true
    }
  }
 
  return false
};




export const validatePin = (pin: string) => {
 
  return (pin ?? "").length === 6;
};


export const validateMobileNumber = (contactPhone: string) => {
  const re = /9[0-9]{9}/;
  if (contactPhone === "" || re.test(contactPhone)) {
    if (contactPhone.length <= 10) {
      return true
    }
  }
 
  return false
}


export const validatePaymentMethod = (paymentMethod: string,  billTo) => {
  
  if(paymentMethod ===  'alleasy')
      return true;
  if(paymentMethod ===  'billing' && (billTo ?? "").length > 10)
      return true;
 
  return false
};
