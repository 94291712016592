import { post, put, postMultipart, getBlob } from '../core';
import fileDownload from "js-file-download";
import moment from 'moment';

export const addParcel = async(body: any) => {
    try{
        const res = await post('/customer/create/parcel', body)
        return res.data;
    }catch(err){
        return Promise.reject(err);
    }
}

export const scheduleParcelBooking = async(body: any) => {
  try{
    const res = await put('/customer/schedule/booked-parcel', body)
    return res.data;
  }catch(err){
   return Promise.reject(err)
  }
}

export const addBulkParcel =  async(file: any) => {
  try{
    const body: any = new FormData();
    body.append('file', file);
    body.append('name', "Bulk Product")
    
    const res = await postMultipart('/upload/parcel', body);
    return res.data;
  }catch(err){
    return Promise.reject(err);
  }
}

export const generatePdf = async (body: any) => {
  try {
    let res = await getBlob('/generate/pdf/waybill', body);
    fileDownload(res.data, `${Date.now()}-waybill$.pdf`);
  } catch (err) {
    console.log("error", err);
    throw err;
  }
};

export const getOrderBreakdown = async(body: any) => {
  try{
    const res = await post('/customer/parcel/calculate-fare', body)
    return res.data;
  }catch(err){
    throw err;
  }
}