import { createModel } from "@rematch/core";
import {
  authenticateViaPassword,
  authenticateViaAllEasy,
  getUserCredentials,
  updateUserProfile,
  updateUserBank,
  registerUser,
} from "../common/api/endpoints/login";
import { IUserInfo, ISignUp } from "../types"; 
import { RootModel } from ".";
import { initialState as OrderInitialState } from './OrderModel'
import { getFirebaseToken } from "../common/utils";
import firebase from "firebase/app";
import 'firebase/firestore';

export interface State {
  customToken: string;
  isUserLoggedIn: boolean;
  signupComplete: boolean;
  isAllEasyModalOpen: boolean;
  userInfo: IUserInfo | null;
  userSignUp: ISignUp | null;
  userToken: string;
  shouldRedirectToLogin: boolean;
  accountAddress: Array<any> | null;
  resetPasswordCount: number;
}

const initialState = {
  customToken: "",
  isUserLoggedIn: false,
  signupComplete: false,
  isAllEasyModalOpen: false,
  shouldRedirectToLogin: false,
  userInfo: null,
  userSignUp: null,
  userToken: "",
  accountAddress: null,
  resetPasswordCount: 0,
} as State

export const User = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetState() {
      return { ...initialState}
    },
    setUserLoggedIn(state, payload: boolean) {
      state.isUserLoggedIn = payload;
      return { ...state };
    },
    setUserInfo(state, payload: IUserInfo | null) {
      state.userInfo = payload;
      return { ...state };
    },
    setSignUp(state, payload: ISignUp | null) {
      state.userSignUp = payload;
      return { ...state };
    },
    setUserToken(state, payload: string) {
      state.userToken = payload;
      return { ...state };
    },
    updateState(state, newState: Partial<State>) {
      return { ...state, ...newState }
    },
  },
  effects: (dispatch) => ({
    /**
     * @name login
     * @desc login user using email and password
     */
    async login(payload, rootState) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });


      try {
        let res = await authenticateViaPassword({
          userEmailPhone: rootState.UI.forms.login.fields.userEmailPhone,
          password: rootState.UI.forms.login.fields.password,
        });

        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken)
        // console.log(userToken)
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();
      } catch (err: any) {
        dispatch.UI.setErrorMsg({
          target: "login",
          value: err.response?.data?.error,
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    async AllEasyLogin(pin:string, rootState) {
      dispatch.UI.setIsUserLoading({ target: "login", value: true });
      if(pin.length !== 6){
        return;
      }


      try {
        let res = await authenticateViaAllEasy({
          phone: (rootState.User?.userInfo?.phoneNumber ?? "").replace("+63", ""),
          pin: pin,
        });


        const customToken = res.accessToken
        
        const {  userToken } = await getFirebaseToken(customToken)
        // console.log(userToken)
        dispatch.User.updateState({ customToken: customToken, userToken });
        
        dispatch.User.updateState({
          customToken: userToken,
          userToken
        });

        dispatch.UI.setErrorMsg({
          target: "login",
          value: "",
        });

        dispatch.UI.resetForm("login");
        await this.getUserInfoViaToken();

        dispatch.Wallet.getWalletBalance()
      } catch (err: any) {
        console.log("test err", err)
        dispatch.UI.setErrorMsg({
          target: "login",
          value: "Invalid Pin",
        });
      } finally {
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
      }
    },
    /**
     * @name getUserInfoViaToken
     * @desc fetches and sets user data via token(state.userToken)
     */
    async getUserInfoViaToken() {
        let user_profiles = await getUserCredentials();

        dispatch.User.setUserInfo({
          ...user_profiles
        });
        dispatch.User.setUserLoggedIn(true);
    },
    async updateUserProfile (body: Partial<IUserInfo>) {
      dispatch.UI.setIsLoading(true);
      try {
        const data = await updateUserProfile(body)
        if (data.isSuccess) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({ message: 'Profile updated.', type: "success"})
        }
        return true
      } catch (err) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({ message: 'Update failed.', type: 'error'})
        return false
      }
    },
    async updateUserBankDetails (body: Partial<IUserInfo>) {
      dispatch.UI.setIsLoading(true);
      try {
        const data = await updateUserBank(body)
        if (data.isSuccess) {
          dispatch.UI.setIsLoading(false);
          dispatch.UI.setAlert({ message: 'Profile updated.', type: "success"})
        }
        return true
      } catch (err) {
        dispatch.UI.setIsLoading(false);
        dispatch.UI.setAlert({ message: 'Update failed.', type: 'error'})
        return false
      }
    },
    async register(body: Partial<ISignUp>) {
      try{
        dispatch.UI.setIsUserLoading({ target: "login", value: true });
        let res = await registerUser(body); 
        if (res) {
          dispatch.User.updateState({signupComplete: true})
          dispatch.UI.setAlert({ message: `Thank You for Signing Up For ${process.env.REACT_APP_BRAND_NAME}`, type: "success"}) 
          dispatch.UI.setIsUserLoading({ target: "login", value: false });
          dispatch.User.resetState();
        }
      }catch(err: any){
        const errMsg = !err.response ? 'Signup failed.' : err.response.data?.error;
        console.log(err.response.data.error);
        dispatch.UI.setIsUserLoading({ target: "login", value: false });
        dispatch.UI.setAlert({ message: errMsg, type: 'error'})
      }
    },
    async getAccountAddress(payload, rootState) {
      try{
          const userId = rootState.User.userInfo?.userId;
          const db = firebase.firestore();
          
          const querySnapshot: any| [] = await db.collection('address')
            .where('userId', '==', userId)
            .get()

          const listAddress = querySnapshot?.docs?.map(doc => {
            const address = doc.data();
            return address;
          })

          dispatch.User.updateState({accountAddress: listAddress})
      }catch(err){
        console.log("Error getting documents: ", err);
      }
    }
  }),
});
