import { get, post } from "../core";

/**
 * @name getWalletBalance
 * @returns returns object based on the users wallet balance details
 */

 export const getWalletBalance = async () => {
  try {
    let res = await get(`/customers/wallet/balance`);
    return res.data.balance;
  } catch (err) {
    throw err;
  }
};


export const getRecentTransactions = async ()  => {
  try {
    let res = await get(`/customers/wallet/recent-transactions`);
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const requestWalletWithdrawal = async (body: any)  => {
  try {
    let res = await post(`/customer/withdrawal-request`, body);
    return res.data;
  } catch (err) {
    throw err;
  }
};