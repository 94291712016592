import firebase from "../../Firebase";

export const getFirebaseToken = async (customToken: string) => {
    try {
        const firebaseResponse = await firebase.auth().signInWithCustomToken(customToken);
        const userToken = await firebase.auth().currentUser?.getIdToken(true);

        console.log("firebaseResponse",firebaseResponse, userToken)
        return { firebaseResponse, userToken}
    } catch (err) {
        throw err
    }
};