import { FC } from "react";
import { useMapWorker } from "./Map";

const WebWorkers: FC<{}> = ({ children }) => {
  useMapWorker();

  return <>{children}</>;
};

export default WebWorkers;
